import * as React from 'react';
import {useContext} from 'react';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {CombinedContext} from "../../state/data";
import AddAppDialog from "./create";
import {format} from "date-fns";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import Button from "@mui/material/Button";


export default function Apps() {
    const [addAppDialogOpen, setAddAppDialogOpen] = React.useState(false);
    const {apps} = useContext(CombinedContext);

    const columns = [
        {
            field: 'icon',
            headerName: '',
            width: 60,
            renderCell: (params) => (
                <Link href={params.row.url} target="_blank">
                    <img src={params.value} alt={params.row.name}
                         style={{width: '32px', height: '32px', borderRadius: '20%'}}/>
                </Link>
            ),

        },

        {field: 'name', headerName: 'Name', width: 300},
        {
            field: 'created_at',
            headerName: 'Created at',
            width: 200,
            valueFormatter: (params) => format(new Date(params.value), 'HH:mm / dd.MM.yyyy'),
        },
        {
            field: 'verified',
            headerName: 'Verified',
            width: 130,
            renderCell: (params) => (
                params.value ?
                    <CheckCircleIcon style={{color: 'green'}}/> :
                    <HourglassEmptyIcon style={{color: 'orange'}}/>
            ),
        },
    ];


    return (<React.Fragment>
            <AddAppDialog open={addAppDialogOpen} setOpen={setAddAppDialogOpen}/>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}}>
                <Button variant="contained" color="primary" startIcon={<AddIcon/>}
                        onClick={() => setAddAppDialogOpen(true)}
                        style={{height: 40}}
                >
                    Add
                </Button>
            </Box>
            <Box sx={{height: '75vh', width: '100%'}}>
                <DataGrid
                    columns={columns}
                    rows={apps.map((app, index) => ({
                        id: index,
                        name: app.title,
                        icon: app.icon_url,
                        url: app.store_url,
                        verified: app.verified,
                        created_at: app.created_at
                    }))}
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        minHeight: 400,
                    }}
                    disableRowSelectionOnClick={true}
                    disableMultipleRowSelection={true}
                    disableSelectionOnClick={true}
                    rowSelectionModel="none"
                    pageSizeOptions={[5, 10, 25]}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 10}},
                        sorting: {
                            sortModel: [{field: 'created_at', sort: 'desc'}],
                        },
                    }}
                />
            </Box>
        </React.Fragment>
    );
}

