import React, {createContext, useEffect, useState} from 'react';

// Create a new context
export const DateContext = createContext();

const savedFilters = JSON.parse(localStorage.getItem('sk_filters'));

// Create a provider for the new context
export const DateProvider = ({children}) => {
    const [selectedStartDate, setSelectedStartDate] = useState(() => {
        return savedFilters?.selectedStartDate ? new Date(savedFilters.selectedStartDate) : (() => {
            const date = new Date();
            date.setDate(date.getDate() - 7);
            date.setHours(0, 0, 0, 0);
            return date;
        })();
    });
    const [selectedEndDate, setSelectedEndDate] = useState(() => {
        return savedFilters?.selectedEndDate ? new Date(savedFilters.selectedEndDate) : (() => {
            const date = new Date();
            date.setHours(23, 59, 59, 999);
            return date;
        })();
    });

    const [dateRange, setDateRange] = useState(() => {
        return savedFilters?.dateRange || 'last7Days';
    });

    useEffect(() => {
        const filters = {
            selectedStartDate,
            selectedEndDate,
            dateRange,
        };
        localStorage.setItem('sk_filters', JSON.stringify(filters));
    }, [selectedStartDate, selectedEndDate, dateRange]);

    return (
        <DateContext.Provider value={{
            selectedStartDate,
            setSelectedStartDate,
            selectedEndDate,
            setSelectedEndDate,
            dateRange,
            setDateRange
        }}>
            {children}
        </DateContext.Provider>
    );
};
