import React, {createContext, useEffect, useState} from 'react';

export const ViewContext = createContext();

export const ViewProvider = ({children}) => {
    const localStorageKey = 'sk_settings';
    const initialView = localStorage.getItem(localStorageKey) || 'orders';
    const [view, setView] = useState(initialView);

    useEffect(() => {
        localStorage.setItem(localStorageKey, view);
    }, [view]);

    return (
        <ViewContext.Provider value={{view, setView}}>
            {children}
        </ViewContext.Provider>
    );
};
