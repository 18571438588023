import React from 'react';
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import './Home.css'; // Import the CSS file

export default function Home() {
    const navigate = useNavigate();
    return (
        <div className="full-screen-container">
            <div className="header-box">
                <Button variant="contained" sx={{
                    backgroundColor: '#424242',
                    color: '#ffffff',
                    fontSize: '1.1rem',
                    padding: '0.5rem 2rem',
                    borderRadius: '2rem',
                    ":hover": {
                        backgroundColor: '#2a9eb2',
                        color: '#ffffff'
                    }
                }}
                        onClick={() => navigate('/dashboard')}>
                    Dashboard
                </Button>
            </div>
            <div className="centered-content ">
                <div className="huge-text">
                    <h1>Get organic traffic by keyword promotions</h1>
                </div>
            </div>
        </div>
    );
}
