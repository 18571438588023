import * as React from 'react';
import {useContext} from 'react';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import {DateContext} from "../../state/filters";

export default function Filters() {
    const {dateRange, setDateRange} = useContext(DateContext);
    const {selectedStartDate, setSelectedStartDate, selectedEndDate, setSelectedEndDate} = useContext(DateContext);
    
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };
    const handleDateRangeChange = (event) => {
        const date = new Date();
        setDateRange(event.target.value);

        const start = new Date();
        const end = new Date();

        switch (event.target.value) {
            case 'lastMonth':
                start.setMonth(date.getMonth() - 1);
                break;
            case 'last7Days':
                start.setDate(date.getDate() - 7);
                break;
            case 'today':
                break;
            case 'yesterday':
                start.setDate(date.getDate() - 1);
                end.setDate(date.getDate() - 1);
                break;
            default:
                break;
        }

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        setSelectedStartDate(start);
        setSelectedEndDate(end);
    };

    return (
        <Box display="flex" alignItems="center">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Select
                    value={dateRange}
                    onChange={handleDateRangeChange}
                    displayEmpty
                    inputProps={{'aria-label': 'Without label'}}
                    sx={{mr: 2, backgroundColor: 'rgba(0, 0, 0, 0.2)'}}
                >
                    <MenuItem value="" disabled>
                        Select Date Range
                    </MenuItem>
                    <MenuItem value={'today'}>Today</MenuItem>
                    <MenuItem value={'yesterday'}>Yesterday</MenuItem>
                    <MenuItem value={'last7Days'}>Last 7 Days</MenuItem>
                    <MenuItem value={'lastMonth'}>Last Month</MenuItem>
                </Select>
                <DatePicker
                    label="Since"
                    format="dd.MM.yyyy"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    sx={{width: 150, mr: 2, backgroundColor: 'rgba(0, 0, 0, 0.15)'}}
                />
                <DatePicker
                    label="Until"
                    format="dd.MM.yyyy"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    sx={{width: 150, backgroundColor: 'rgba(0, 0, 0, 0.15)'}}
                />
            </LocalizationProvider>
        </Box>
    );
}
