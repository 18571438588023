import * as React from 'react';
import {useContext} from 'react';
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Filters from './filters';
import {changeOrderStatus} from "../../services/api";
import {CombinedContext} from "../../state/data";
import AddOrderDialog from "./create";
import {useTheme} from "@mui/material/styles";
import {LinearProgress, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import {format} from "date-fns";
import {DataGrid} from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";


export default function Orders() {
    const {orders, setOrders} = useContext(CombinedContext);
    const [addOrderDialogOpen, setAddOrderDialogOpen] = React.useState(false);


    const handleOrderStatus = (orderId, status) => {
        changeOrderStatus(orderId, status)
        const order = orders.find(order => order.uuid === orderId);
        order.status = status;
        setOrders([...orders]);
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const columns = [
        {
            field: 'action',
            headerName: '',
            width: 60,
            sortable: false,
            renderCell: (params) => {
                if (params.row.status === 'stopped') {
                    return (
                        <IconButton color="primary" onClick={() => handleOrderStatus(params.row.uuid, 'running')}>
                            <PlayArrowIcon/>
                        </IconButton>
                    );
                } else if (params.row.status === 'finished') {
                    return (
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <IconButton style={{color: 'green'}}>
                                <CheckCircleIcon/>
                            </IconButton>
                        </Box>
                    );
                } else {
                    return (
                        <IconButton color="secondary" onClick={() => handleOrderStatus(params.row.uuid, 'stopped')}>
                            <StopIcon/>
                        </IconButton>
                    );
                }
            },
        },
        {
            field: 'appIcon',
            headerName: '',
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <img src={params.value} alt="App Icon"
                     style={{width: '32px', height: '32px', borderRadius: '20%'}}/>
            ),
        },
        {
            field: 'keyword',
            headerName: 'Keyword',
            flex: 1,
        },
        {
            field: 'progress',
            headerName: 'Progress',
            flex: 1,
            renderCell: (params) => (
                <LinearProgress variant="determinate" value={params.value * 100}
                                style={{width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.4)'}}/>
            ),
        },
        {field: 'installs', headerName: 'Installs', width: 75},
        {field: 'done', headerName: 'Done', width: 75},
        {field: 'cost', headerName: 'Cost', flex: 1},
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            renderCell: (params) => (
                <span style={{
                    color: params.value === 'stopped' ? '#f44336' : '#4caf50',
                }}>{params.value}</span>
            ),
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 160,
            valueFormatter: (params) => format(new Date(params.value), 'HH:mm / dd.MM.yyyy'),
        },
    ]


    return (
        <React.Fragment>
            <AddOrderDialog open={addOrderDialogOpen} setOpen={setAddOrderDialogOpen}/>
            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2}}>
                <Filters/>
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant="contained" color="primary" startIcon={<AddIcon/>}
                            onClick={() => setAddOrderDialogOpen(true)}
                            style={{height: 40}}
                    >
                        Create
                    </Button>
                </Box>
            </Box>
            <Box sx={{height: '75vh', width: '100%'}}>
                <DataGrid
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        minHeight: 400,
                        fontSize: isMobile ? '0.8rem' : '1rem',
                    }}
                    rows={orders.map((row, index) => {
                        const progress = (row.installs && row.installs_done) ? (row.installs_done / row.installs) : 0;
                        return {
                            id: index,
                            uuid: row.uuid,
                            appIcon: row.app_icon_url,
                            keyword: row.keyword,
                            installs: row.installs,
                            done: row.installs_done,
                            progress: progress,
                            status: row.status,
                            cost: row.price,
                            createdAt: row.created_at
                        };
                    })}
                    columns={columns}
                    disableRowSelectionOnClick={true}
                    disableMultipleRowSelection={true}
                    disableSelectionOnClick={true}
                    rowSelectionModel="none"
                    pageSizeOptions={[5, 10, 25]}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 10}},
                        sorting: {
                            sortModel: [{field: 'created_at', sort: 'desc'}],
                        },
                    }}
                />
            </Box>
        </React.Fragment>
    );
}
