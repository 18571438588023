import React, {useContext, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InputAdornment from '@mui/material/InputAdornment';
import {CombinedContext} from "../../state/data";
import {addApp} from "../../services/api";

export default function AddAppDialog({open, setOpen}) {
    const [url, setUrl] = useState('');
    const [isValid, setIsValid] = useState(false);
    const {refreshApps} = useContext(CombinedContext);

    const handleClose = () => {
        setOpen(false);
        setUrl('');
        setIsValid(false);
    };

    const handleAdd = async () => {
        handleClose();
        await addApp(url);
        refreshApps();
    };

    const handleUrlChange = (event) => {
        setUrl(event.target.value);

        // Regular expression for URL validation
        const regex = /^https:\/\/apps\.apple\.com\/.*\/id\d+$/;

        // Check if the URL is valid
        setIsValid(regex.test(event.target.value));
    };


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add New App</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="url"
                    label="URL"
                    type="url"
                    fullWidth
                    value={url}
                    onChange={handleUrlChange}
                    error={!isValid && url !== ''}
                    helperText={!isValid && url !== '' ? 'Invalid URL. Please enter a valid URL.' : ' '}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {isValid ? <CheckCircleIcon color="success"/> : url !== '' ?
                                    <ErrorIcon color="error"/> : null}
                            </InputAdornment>
                        ),
                    }}
                    FormHelperTextProps={{
                        style: {
                            color: (!isValid && url !== '') ? 'inherit' : 'transparent'
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAdd} disabled={!isValid}>Add</Button>
            </DialogActions>
        </Dialog>
    );
}
