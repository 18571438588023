import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Orders from '../orders/orders';
import Apps from '../app/apps';
import {AppBar, Drawer, MainListItems, secondaryListItems} from "./side";
import {CombinedContext} from "../../state/data";
import {DateContext} from "../../state/filters";
import {getUser} from "../../services/api";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {ViewContext} from "../../state/view";

export default function Dashboard() {
    const isMobile = window.matchMedia('(max-width: 600px)').matches;
    const [open, setOpen] = React.useState(false);
    const {view, setView} = React.useContext(ViewContext);
    const {refreshApps} = React.useContext(CombinedContext);
    const {refreshOrders} = React.useContext(CombinedContext);
    const {selectedStartDate, selectedEndDate,} = React.useContext(DateContext);
    const [balance, setBalance] = React.useState(0);
    const [openBalanceInfo, setOpenBalanceInfo] = React.useState(false);
    const handleClickOpen = () => {
        setOpenBalanceInfo(true);
    };

    const handleClose = () => {
        setOpenBalanceInfo(false);
    };

    React.useEffect(() => {
        const fetchBalance = async () => {
            const user = await getUser();
            setBalance(user.balance);
            console.log(user)
        }

        fetchBalance();
    }, []);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const changeView = (newView) => { // Add this function
        setView(newView);
    };


    React.useEffect(() => {
        switch (view) {
            case 'orders':
                refreshOrders(selectedStartDate, selectedEndDate);
                refreshApps();
                break;
            case 'App':
                refreshApps();
                break;
            default:
                break;
        }
    }, [view, selectedStartDate, selectedEndDate, refreshOrders, refreshApps]);

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="absolute" open={open}>
                <Toolbar sx={{pr: '24px'}}>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && {display: 'none'}),
                                }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{flexGrow: 1}}>
                        {view}
                    </Typography>
                    <Button variant="outlined" onClick={handleClickOpen}>
                        <Box component="span" display="flex" alignItems="center">
                            <AccountBalanceWalletIcon sx={{paddingRight: '8px'}}/> {balance}
                        </Box>
                    </Button>

                    <Dialog open={openBalanceInfo} onClose={handleClose}>
                        <DialogTitle>{"Balance Information"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                You could top up your balance with Wire Transfer / USDT / Card payment. Please
                                contact{" "}
                                <a href="http://t.me/sofikeyio" target="_blank" rel="noreferrer"
                                   style={{color: 'pink'}}>@sofikeyio </a>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>OK</Button>
                        </DialogActions>
                    </Dialog>
                </Toolbar>
            </AppBar>
            <Box sx={{display: 'flex'}}>
                <Drawer variant="permanent" open={isMobile ? false : open}>
                    <Toolbar sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1]}}>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">
                        {MainListItems(changeView)}
                    </List>
                    <Box sx={{flexGrow: 1}}/>
                    <List component="nav">
                        <Divider sx={{my: 1}}/>
                        {secondaryListItems(changeView)}
                    </List>
                </Drawer>
            </Box>
            <Box component="main" sx={{flexGrow: 1, height: '100vh', overflow: 'auto'}}>
                <Toolbar/>
                <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{p: 2, minHeight: '400px'}}>
                                {view === 'orders' && <Orders/>}
                                {view === 'App' && <Apps/>}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}
