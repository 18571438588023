import './App.css';
import Dashboard from './features/dashboard/dashboard';
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect} from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthView from "./features/auth/auth";
import Home from "./features/home/Home";
import {CombinedProvider} from "./state/data";
import {DateProvider} from "./state/filters";
import {ViewProvider} from "./state/view";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function CheckToken() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('sofi_client_token');

        if (!token && location.pathname === '/dashboard') {
            navigate('/auth');
        }
    }, [navigate, location]);

    return null;
}

function NotFound() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, [navigate]);
    return null;
}

export default function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <ToastContainer theme="dark"/>
            <CssBaseline/>

            <DateProvider>
                <CombinedProvider>
                    <ViewProvider>
                        <Router>
                            <CheckToken/>
                            <Routes>
                                <Route path="/auth" element={<AuthView/>}/>
                                <Route path="/dashboard" element={<Dashboard/>}/>
                                <Route path="/" element={<Home/>}/>
                                <Route path="*" element={<NotFound/>}/>
                            </Routes>
                        </Router>
                    </ViewProvider>
                </CombinedProvider>
            </DateProvider>

        </ThemeProvider>
    );
}
