import React, {createContext, useCallback} from 'react';
import {getApps, getOrders} from "../services/api";


// Create a context
export const CombinedContext = createContext();

// Create a provider
export const CombinedProvider = ({children}) => {
    const [apps, setApps] = React.useState([]);
    const [orders, setOrders] = React.useState([]);

    const refreshApps = useCallback(async () => {
        const fetchedApps = await getApps();
        setApps(fetchedApps);
    }, []);

    const refreshOrders = useCallback(async (start, end) => {
        const fetchedOrders = await getOrders(start, end);
        setOrders(fetchedOrders);
    }, []);

    return (
        <CombinedContext.Provider value={{apps, orders, refreshApps, refreshOrders, setOrders}}>
            {children}
        </CombinedContext.Provider>
    );
}
