import axios from 'axios';
import {toast} from "react-toastify";

const API_URL = 'https://api.sofikey.io';

export const getApps = async () => {
    try {
        const token = localStorage.getItem('sofi_client_token');
        const response = await axios.get(`${API_URL}/apps`, {
            headers: {
                Authorization: `${token}`,
                "Content-Type": "application/json",
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed to get apps:', error);
    }
}

export const getOrders = async (since, until, limit, offset) => {
    try {
        const token = localStorage.getItem('sofi_client_token');
        if (!token) {
            return
        }

        let params = {};
        if (since) params.since = since.toISOString();
        if (until) params.until = until.toISOString();
        if (limit) params.limit = limit;
        if (offset) params.offset = offset;
        const response = await axios.get('https://api.sofikey.io/orders', {
            params: params,
            headers: {
                Authorization: `${localStorage.getItem('sofi_client_token')}`
            }
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        toast.error(`Failed to get orders: ${error.message}`);
    }
}

export const changeOrderStatus = async (orderId, status) => {
    try {
        const response = await axios.patch(`https://api.sofikey.io/order/${orderId}`, null, {
            params: {
                status: status
            },
            headers: {
                Authorization: `${localStorage.getItem('sofi_client_token')}`
            }
        });
        if (response.status === 200) {
            toast.success(`Order ${status} successfully`);
            return response.data;
        }
    } catch (error) {
        toast.error(`Failed to ${status} order: ${error.message}`);
    }
}

export const addApp = async (url) => {
    try {
        // Send a POST request to the server
        const response = await axios.post('https://api.sofikey.io/app', {
            url: url
        }, {
            headers: {
                'accept': 'application/json',
                'Authorization': localStorage.getItem('sofi_client_token'),
                'Content-Type': 'application/json'
            }
        });

        // Check the response status
        if (response.status === 200) {
            toast.success('App added successfully');
        } else {
            toast.error('Failed to add app with status code ' + response.status);
        }
    } catch (error) {
        toast.error('Failed to add app with error ' + error);
    }
}

export const createOrder = async (app_uuid, installs, keyword) => {


    try {
        const response = await axios.post('https://api.sofikey.io/order', {
            app_uuid: app_uuid,
            installs: Number(installs),
            keyword: keyword
        }, {
            headers: {
                'accept': 'application/json',
                'Authorization': localStorage.getItem('sofi_client_token'),
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            toast.success('Order created successfully');
        } else {
            toast.error('Failed to create order with status code ' + response.status);
        }
    } catch (error) {
        toast.error('Failed to create order with error ' + error);
    }
}

export const login = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}/auth`, null, {
            params: {
                email,
                password
            }
        });

        // Assuming the token is returned in the response data
        const token = response.data;

        // Save the token to localStorage

        localStorage.setItem('sofi_client_token', token);
    } catch (error) {
        console.error('Failed to login:', error);
        throw error;
    }
};

export const register = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}/user`, null, {
            params: {
                email,
                password
            }
        });

        // Assuming the token is returned in the response data
        const token = response.data;

        // Save the token to localStorage
        localStorage.setItem('sofi_client_token', token);
    } catch (error) {
        console.error('Failed to register:', error);
        throw error;
    }
};

export const getUser = async () => {
    try {
        const response = await axios.get(`${API_URL}/user`, {
            headers: {
                Authorization: `${localStorage.getItem('sofi_client_token')}`
            }
        });

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        toast.error(`Failed to get user: ${error.message}`);
    }
}
