import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {createOrder} from "../../services/api";
import {CombinedContext} from "../../state/data";
import {DateContext} from "../../state/filters";

export default function AddOrderDialog({open, setOpen}) {
    const [keyword, setKeyword] = useState('');
    const [installs, setInstalls] = useState('');
    const {apps} = useContext(CombinedContext);
    const {refreshOrders} = useContext(CombinedContext);
    const {selectedStartDate, selectedEndDate} = useContext(DateContext);
    const [selectedApp, setSelectedApp] = useState('');

    const handleCreate = () => {
        handleClose();
        createOrder(selectedApp, installs, keyword).then(r => {
            refreshOrders(selectedStartDate, selectedEndDate);
        });
    };

    useEffect(() => {
        if (apps && apps.length > 0) {
            setSelectedApp(apps[0].uuid);
        }
    }, [apps]);

    const handleClose = () => {
        setOpen(false);
        setSelectedApp('');
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'xs'} fullWidth>
            <DialogTitle>Create New Order</DialogTitle>
            <DialogContent>
                <Select
                    value={selectedApp}
                    onChange={(event) => setSelectedApp(event.target.value)}
                    fullWidth
                >
                    {apps && apps.filter(app => app.verified).map((app, index) => (
                        <MenuItem key={app.id} value={app.uuid}>
                            <img src={app.icon_url} alt={app.title}
                                 style={{width: '20px', height: '20px', marginRight: '10px'}}/>
                            {app.title}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    autoFocus
                    margin="dense"
                    id="keyword"
                    label="Keyword"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(event) => setKeyword(event.target.value)}
                />
                <TextField
                    margin="dense"
                    id="installs"
                    label="Installs"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={(event) => setInstalls(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
            </DialogActions>
        </Dialog>
    );
}
