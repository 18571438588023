import React, {useState} from 'react';
import {login, register} from "../../services/api";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

export default function AuthView() {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(true);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');
        try {
            if (isLogin) {
                await login(email, password);
            } else {
                await register(email, password);
            }
            navigate('/dashboard');

        } catch (error) {
            toast.error(isLogin ? "Failed to login" : "Failed to register");
        }
    };

    const handleEmailChange = (event) => {
        const email = event.target.value;
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailRegex.test(email)) {
            setEmailError("Invalid email address");
        } else {
            setEmailError("");
        }
    };

    const handlePasswordChange = (event) => {
        const password = event.target.value;
        if (password.length < 8) {
            setPasswordError("Password must be at least 8 characters long");
        } else {
            setPasswordError("");
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            height: '100vh',
        }}>
            <Box sx={{
                display: 'flex',
                boxShadow: '0px 4px 0px rgba(255, 255, 255, 0.1)',
                flexDirection: 'column',
                alignItems: 'center',
                bgcolor: 'background.paper',
                borderRadius: 4,
                p: 3,
            }}>
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    {isLogin ? <LoginIcon/> : <PersonAddIcon/>}
                </Avatar>
                <Typography component="h1" variant="h5">
                    {isLogin ? "Login" : "Registration"}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} width={300}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleEmailChange}
                        error={!!emailError}
                        helperText={emailError}
                        style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handlePasswordChange}
                        error={!!passwordError}
                        helperText={passwordError}
                        style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Submit
                    </Button>
                    <Grid container justifyContent={"right"}>
                        <Grid item>
                            <Button onClick={() => setIsLogin(!isLogin)} variant="body2">
                                {isLogin ? "Register" : "Login"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

